<template>
  <div>
    <!-- 风控大屏-基础概况 -->
    <div class="page-main">
      <!-- 基础概况 -->
      <CardWrap
        title="基础概况(只计算最近半年的活跃用户)"
        class="basis"
      >
        <div class="layout-header">
          <div class="grid-content bg-purple">
            <div class="title">
              {{ numberFormat(baseInfo.totalIncomeAmount+baseInfo.totalRmbIncomeAmount,'Object').value||0 }}<span class="unit">{{ numberFormat(baseInfo.totalIncomeAmount+baseInfo.totalRmbIncomeAmount,'Object').unit }}元</span>
            </div>
            <div class="sub-title">
              <div class="label-type">
                累计营收数据（自{{ baseInfo.startDate }}计算）
              </div>
              <div>
                <span class="label">上月新增充值:<span class="label-content"><span class="highlight">{{ numberFormat(baseInfo.lastMonthTotalRmbIncomeAmount+baseInfo.lastMonthTotalIncomeAmount)||0 }}元</span>，余额<span class="highlight">{{ numberFormat(baseInfo.currentAmount+baseInfo.currentRmbAmount)||0 }}元</span></span></span>
              </div>
            </div>
          </div>
          <div class="grid-content bg-purple">
            <div class="title">
              {{ computeCount(baseInfo.orgCount,orgAllCount) }}%
            </div>
            <div class="sub-title">
              <div class="label-type">
                药企覆盖率:<span class="highlight">{{ baseInfo.orgCount||0 }}家</span>/<span class="highlight">{{ orgAllCount }}家</span>
              </div>
              <span class="label">上月新增客户:<span class="label-content"><span class="highlight">{{ baseInfo.lastMonthNewOrgCount||0 }}家</span>，</span></span>
              <span class="label">活跃药企:<span class="label-content"><span class="highlight">{{ baseInfo.activeOrgCount||0 }}家</span></span></span>
            </div>
          </div>
          <div class="grid-content bg-purple">
            <div class="title">
              {{ computeCount(baseInfo.doctorCount,doctorAllCount) }}%
            </div>
            <div class="sub-title">
              <div class="label-type">
                医生覆盖率:<span class="highlight">{{ numberFormat(baseInfo.doctorCount)||0 }}人</span>/<span class="highlight">{{ numberFormat(doctorAllCount) }}人</span>
              </div>
              <span class="label">上月新增覆盖:<span class="label-content"><span class="highlight">{{ baseInfo.lastMonthNewDoctorCount||0 }}人</span>，</span></span>
              <span class="label">活跃医生:<span class="label-content"><span class="highlight">{{ baseInfo.activeDoctorCount ||0 }}人</span></span></span>
            </div>
          </div>
          <div class="grid-content bg-purple">
            <div class="title">
              {{ computeCount(baseInfo.hospitalCount,hospitalAllCount) }}%
            </div>
            <div class="sub-title">
              <div class="label-type">
                医院覆盖率:<span class="highlight">{{ baseInfo.hospitalCount||0 }}家</span>/<span class="highlight">{{ hospitalAllCount }}家</span>
              </div>
              <div>
                <span class="label">上月新增覆盖:<span class="label-content"><span class="highlight">{{ baseInfo.lastMonthNewHospitalCount||0 }}家</span>，</span></span>
                <span class="label">活跃医院:<span class="label-content"><span class="highlight">{{ baseInfo.activeHospitalCount||0 }}家</span></span></span>
              </div>
            </div>
          </div>
        </div>
      </CardWrap>
      <!-- 基础概况折线图 -->
      <CardWrap class="chart">
        <div class="chart-line-wrap">
          <!-- 充值-领取-兑换趋势 -->
          <div class="grid-content bg-purple">
            <LineBarChart :options="rechargeOpt" />
          </div>
          <!-- 公司增长/活跃趋势 -->
          <div class="grid-content bg-purple">
            <LineBarChart :options="companyOpt" />
          </div>
          <!-- 厂商增长/活跃趋势 -->
          <div class="grid-content bg-purple">
            <LineBarChart :options="vendorOpt" />
          </div>
          <!-- 医生增长/活跃趋势 -->
          <div class="grid-content bg-purple-light">
            <LineBarChart :options="doctorOpt" />
          </div>
          <!-- 代表增长/活跃趋势 -->
          <div class="grid-content bg-purple">
            <LineBarChart :options="delegateOpt" />
          </div>
          <!-- 代表人效趋势 -->
          <div class="grid-content bg-purple">
            <LineBarChart :options="efficiencyOpt" />
          </div>
        </div>
      </CardWrap>
    </div>
  </div>
</template>

<script>
import { platformBaseInfo, getOrgDoctorDataTrend } from '@/apis/dataScreen'
export default {
  name: 'BasicOverview',
  data() {
    return {
      baseInfo: {},
      typeList: {
        19: '人均完成科会场次',
        20: '人均完成调研场次',
        15: '活跃代表数',
        14: '注册代表数',
        12: '注册医生数',
        13: '活跃医生数',
        8: '总企业数',
        9: '活跃企业数',
        10: '总厂商数',
        11: '活跃厂商数',
        26: '充值',
        4: '领取',
        16: '兑换'
      },
      orgAllCount: 4500, // 药企总数
      doctorAllCount: 3860000, // 医生总数
      hospitalAllCount: 34354, // 医院总数
      // 充值-领取-兑换趋势（单位：万元）
      rechargeOpt: {
        title: {
          text: '充值-领取-兑换趋势（单位：万元）'
        },
        legend: {
          data: ['充值', '领取', '兑换']
        }
      },
      // 公司增长/活跃趋势
      companyOpt: {
        title: {
          text: '公司增长/活跃趋势'
        },
        legend: {
          data: ['总企业数', '活跃企业数']
        }
      },
      // 厂商增长/活跃趋势
      vendorOpt: {
        title: {
          text: '厂商增长/活跃趋势'
        },
        legend: {
          data: ['总厂商数', '活跃厂商数']
        }
      },
      // 医生增长/活跃趋势（单位：人）
      doctorOpt: {
        title: {
          text: '医生增长/活跃趋势（单位：人）'
        },
        legend: {
          data: ['注册医生数', '活跃医生数']
        }
      },
      // 代表增长/活跃趋势（单位：人）
      delegateOpt: {
        title: {
          text: '代表增长/活跃趋势（单位：人）'
        },
        legend: {
          data: ['注册代表数', '活跃代表数']
        }
      },
      // 代表人效趋势
      efficiencyOpt: {
        title: {
          text: '代表人效趋势'
        },
        legend: {
          data: ['人均完成科会场次', '人均完成调研场次']
        }
      }
    }
  },
  mounted() {
    this.getData()
    // 充值-领取-兑换趋势（单位：万元）
    this.getTrendData(this.getTypes('rechargeOpt'))
    // 公司增长/活跃趋势
    this.getTrendData(this.getTypes('companyOpt'))
    // 厂商增长/活跃趋势
    this.getTrendData(this.getTypes('vendorOpt'))
    // 医生增长/活跃趋势（单位：人）
    this.getTrendData(this.getTypes('doctorOpt'))
    // 代表增长/活跃趋势（单位：人）
    this.getTrendData(this.getTypes('delegateOpt'))
    // 代表人效趋势
    this.getTrendData(this.getTypes('efficiencyOpt'))
  },
  methods: {
    // 获取基础数据
    async getData() {
      try {
        const res = await platformBaseInfo()
        this.baseInfo = res
      } catch (error) {
        console.log(error)
      }
    },
    // 获取
    getTypes(targetName) {
      const types = this[targetName].legend.data.map(item => {
        for (const key in this.typeList) {
          if (item === this.typeList[key]) {
            return key
          }
        }
      }).filter(v => v).join(',')
      return {
        targetName,
        types: { types }
      }
    },
    // 获取趋势数据
    async getTrendData({ types, targetName }) {
      try {
        const rootData = await getOrgDoctorDataTrend(types)
        this.fomratTrendData({ rootData, targetName })
      } catch (error) {
        console.log(error)
      }
    },
    // 格式化趋势折线图
    fomratTrendData({ rootData = {}, targetName }) {
      const seriesArr = []
      const xArr = new Set()
      for (const key in rootData) {
        const obj = {}
        obj.name = this.typeList[key]
        obj.type = 'line'
        obj.data = rootData[key].map(item => {
          xArr.add(item.dateStr)
          return item.count
        })
        seriesArr.push(obj)
      }
      this.$set(this[targetName], 'series', seriesArr)
      this.$set(this[targetName], 'xAxis', { data: Array.from(xArr) })
    },
    // 格式化单位 (元 ===> '万', '亿', '万亿')
    numberFormat(value = 0, returnType = 'String', toFixed = 0) {
      try {
        if (!Number(value)) {
          value = 0
        }
        const param = {}
        const k = 10000
        const sizes = ['', '万', '亿', '万亿']
        let i = 0
        if (value < k) {
          param.value = value
          param.unit = ''
        } else {
          i = Math.floor(Math.log(value) / Math.log(k))

          if (sizes[i] === '亿') toFixed = toFixed || 4
          param.value = ((value / Math.pow(k, i))).toFixed(toFixed)
          param.unit = sizes[i]
        }
        if (returnType === 'Object') {
          return param
        } else if (returnType === 'String') {
          return param.value + param.unit
        }
        return ''
      } catch (error) {
        return ''
      }
    },

    // 计算html渲染数据
    computeCount(molecular = 0, benominator = 0) {
      const num = (molecular / benominator * 100)
      return this.toFixed(num)
    },
    // 格式化数据
    toFixed(target) {
      return Math.ceil(target) || 0
      // return (Math.round(target * Math.pow(10, len)) / Math.pow(10, len)) || 0
    }
  }
}
</script>

<style lang="scss" scoped>
//scss
.layout-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}
.highlight {
  color: #309890;
  font-weight: 500;
  margin-left: 5px;
}
.grid-content {
  border-radius: 4px;
  background: #fff;
  box-shadow: 2px 2px 4px 0 rgba(234, 236, 240, 0.5);
  box-sizing: border-box;
}
.basis {
  .grid-content {
    padding: 15px 20px;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #333;
      .unit {
        font-size: 16px;
      }
    }
    .sub-title {
      color: #7e8caa;
      line-height: 30px;
      margin-top: 15px;
      font-size: 14px;
      .label {
        margin-right: 10px;
      }
    }
  }
}
.chart {
  .chart-line-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 25px;
    margin-bottom: 25px;
    .grid-content {
      height: 370px;
      > div {
        width: 100%;
        height: 100%;
        padding: 15px;
      }
    }
  }
}
</style>
