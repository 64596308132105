<template>
  <CardWrap title="厂商增长/流失预警  最近三个月环比增长率">
    <div class="table">
      <div class="search-wrap">
        <span class="label">共{{ pagination.total }}个厂家</span>
        <div class="right-form">
          <!-- <el-input v-model="search.name" placeholder="搜索直播名称" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" />
                        </el-input> -->
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        ref="table"
        v-loading="loading"
        border
        class="list-table"
        :data="tableData.list"
        @sort-change="onSortChange"
      >
        <el-table-column
          align="center"
          prop="title"
          label="基础数据"
        >
          <el-table-column
            prop="name"
            align="center"
            label="公司"
          />
          <el-table-column
            prop="accumulativeChargeGift"
            align="center"
            label="累计充值金额"
          />
          <el-table-column
            prop="giftCount"
            align="center"
            label="余额"
          />
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="最近四个月发放积分总额"
        >
          <el-table-column
            align="center"
            prop="lastFourMonthIssueGift"
            :label="`${(tableData.giftMonths[3])}月`"
          />
          <el-table-column
            align="center"
            prop="lastThreeMonthIssueGift"
            :label="`${tableData.giftMonths[2]}月`"
          />
          <el-table-column
            align="center"
            prop="lastTwoMonthIssueGift"
            :label="`${tableData.giftMonths[1]}月`"
          />
          <el-table-column
            align="center"
            prop="lastMonthIssueGift"
            :label="`${tableData.giftMonths[0]}月`"
          />
        </el-table-column>
        <el-table-column
          align="center"
          sortable="custom"
          prop="changeType"
          label="最近三个月环比增长率"
        >
          <el-table-column
            align="center"
            prop="lastThreeMonthGrowthRating"
            :label="`${tableData.ratingMonths[2]}月`"
          >
            <template slot-scope="scoped">
              <span :class="upAndDown(scoped.row.lastThreeMonthGrowthRating)">{{ toPercent(scoped.row.lastThreeMonthGrowthRating) }}
                <svg-icon :name="upAndDown(scoped.row.lastThreeMonthGrowthRating)" />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="lastTwoMonthGrowthRating"
            :label="`${tableData.ratingMonths[1]}月`"
          >
            <template slot-scope="scoped">
              <span :class="upAndDown(scoped.row.lastTwoMonthGrowthRating)">{{ toPercent(scoped.row.lastTwoMonthGrowthRating) }}
                <svg-icon :name="upAndDown(scoped.row.lastTwoMonthGrowthRating)" />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="lastMonthGrowthRating"
            :label="`${tableData.ratingMonths[0]}月`"
          >
            <template slot-scope="scoped">
              <span :class="upAndDown(scoped.row.lastMonthGrowthRating)">{{ toPercent(scoped.row.lastMonthGrowthRating) }}
                <svg-icon :name="upAndDown(scoped.row.lastMonthGrowthRating)" />
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          prop="changeType"
          label="上月代表人效"
        >
          <el-table-column
            align="center"
            prop="averageMeetingCount"
            label="人均科会场次"
          >
            <template slot-scope="scoped">
              {{ scoped.row.averageMeetingCount }}次
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="averageQuestionnaireCount"
            label="人均调研场次"
          >
            <template slot-scope="scoped">
              {{ scoped.row.averageQuestionnaireCount }}次
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="averageSurveyCount"
            label="人均拜访"
          >
            <template slot-scope="scoped">
              {{ scoped.row.averageSurveyCount }}次
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="pagination-wrap">
        <el-pagination
          center
          :current-page="pagination.pageNo"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :page-sizes="pagination.sizes"
          :layout="pagination.layout"
          :hide-on-single-page="false"
          background
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </div>
    </div>
  </CardWrap>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { addLossWarningList } from '@/apis/dataScreen'
export default {
  name: 'Manufacturer',
  mixins: [paginationMixin],
  data() {
    return {
      search: {},
      tableData: {
        list: [],
        giftMonths: ['/', '/', '/', '/'],
        ratingMonths: ['/', '/', '/']
      },
      loading: false,
      currentMonth: ''
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 厂商增长/流失预警  最近三个月环比增长率
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const res = await addLossWarningList(params)
        this.loading = false
        const { data, totalCount } = res
        this.tableData = {
          list: [],
          giftMonths: [],
          ratingMonths: [],
          ...data
        }
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
      }
    },
    // 排序
    onSortChange({ order }) {
      this.search.asc = order === 'ascending'
      this.getDataList()
    },
    // 处理涨跌类名
    upAndDown(value = 0) {
      return value > 0 ? 'up' : value < 0 ? 'down' : ''
    },
    // 小数转百分数
    toPercent(value) {
      return `${parseFloat((value * 100).toPrecision(12))}%`
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.table {
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 4px 0 rgba(234, 236, 240, 0.5);
  border-radius: 4px;
  .up {
    color: #f00;
  }
  .down {
    color: #008000;
  }
}
:deep(.list-table) {
  .caret-wrapper {
    width: 34px;
    .sort-caret.ascending {
      left: 18px;
      &::after {
        content: '';
        width: 2px;
        height: 5px;
        background-color: #c0c4cc;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .sort-caret.descending {
      &::after {
        content: '';
        width: 2px;
        height: 5px;
        background-color: #c0c4cc;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .descending .sort-caret.descending,
  .ascending .sort-caret.ascending {
    &::after {
      background-color: #008075;
    }
  }
}
.search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
