<template>
  <!-- 风控管理-场景管理 -->
  <div class="page-main">
    <BasicOverview ref="basicOverview" />
    <!-- <MedicineData ref="medicineData" /> -->
    <!-- <DocterData ref="docterData" /> -->
    <Manufacturer ref="manufacturer" />
    <!-- <DocterArea ref="docterArea" /> -->
  </div>
</template>

<script>
import BasicOverview from './components/BasicOverview'
import Manufacturer from './components/Manufacturer'
// import MedicineData from './components/MedicineData'
// import DocterData from './components/DocterData'
// import DocterArea from './components/DocterArea'

import chartResize from '@/mixins/chartResize'
export default {
  name: 'ScreenControl',
  components: {
    // MedicineData,
    // DocterArea,
    // DocterData
    BasicOverview,
    Manufacturer
  },
  mixins: [chartResize],
  data() {
    return {
      tableData: [],
      timmer: null, // 定时器
      windowWidth: '' // 屏幕宽度
    }
  },
  methods: {
    //  窗口宽度变化
    chartResize() {
      console.log('窗口变化了')
      // this.$refs.basicOverview.onResize()
      // this.$refs.medicineData.onResize()
      // this.$refs.docterData.onResize()
      // this.$refs.docterArea.onResize()
    }
  }
}
</script>

<style lang="scss" scoped>
// scss
.page-main {
  background: #f5f7fb;
  padding: 20px;
  box-sizing: border-box;
}
</style>
