import request from '@/utils/request'
import API from './api-types'
// 趣学术平台数据大屏-基础数据
export function platformBaseInfo(params) {
  return request({
    url: API.platformBaseInfo,
    method: 'get',
    params
  })
}
// 厂家流失增加预警
export function addLossWarningList(params) {
  return request({
    url: API.addLossWarningList,
    method: 'get',
    params
  })
}
// 获取厂家趋势数据
export function getOrgDoctorDataTrend(params) {
  return request({
    url: API.getOrgDoctorDataTrend,
    method: 'get',
    params
  })
}
